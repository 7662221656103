import React, { Component } from 'react';
import { Button, Form, Message, Dimmer, Loader, Container } from 'semantic-ui-react';
import { Link } from 'gatsby';
import { Row, Column } from '../components/common/grid';
import { USER_API_URL } from '../constants/constants';
import Axios from 'axios';
import { navigate } from 'gatsby';
import { toast } from 'react-toastify';

class Register extends Component {
  state = {
    email: '',
    password: '',
    password2: '',
    errors: false,
    errorMessage: '',
    loading: false,
    isEmployer: false,
    isApplicant: false
  };

  validateForm = () => {
    if (
      this.state.email.trim().length > 0 &&
      this.state.password.trim().length > 0 &&
      this.state.password.trim().length > 0 &&
      this.state.password.trim() === this.state.password2.trim()
    ) {
      this.setState({ errors: false });
      return true;
    } else {
      this.setState({ errors: true, errorMessage: 'All fields are required' });
      return false;
    }
  };

  handleSubmit = () => {
    this.setState({ errors: false, errorMessage: '', loading: true });
    const user = {
      email: this.state.email,
      password: this.state.password
    };
    if (this.validateForm()) {
      this.handleRegister(user);
    } else {
      this.setState({ errors: true, loading: false });
    }
  };

  handleIsApplicant = () => {
    toast.info('Accounts are for job posters only. Check back soon for applicant accounts.');
    navigate('/');
  };

  handleIsEmployer = () => {
    this.setState({
      isEmployer: true,
      isApplicant: false
    });
  };

  handleRegister = user => {
    Axios.post(`${USER_API_URL}/user`, user)
      .then(response => {
        if (response.data.success) {
          toast.success('Registration successful. Sign in now.');
          localStorage.setItem('userId', response.data.id);
          this.setState({ userId: response.data.id, loading: false });
          navigate('/login');
        } else {
          this.setState({ errors: true, errorMessage: response.data.message, loading: false });
        }
      })
      .catch(error => {
        toast.error('Error registering: ' + error);
        this.setState({ loading: false });
      });
  };

  render() {
    return (
      <Container>
        {/* 
        // @ts-ignore */}
        {this.state.isEmployer ? (
          <>
            <Row>
              <Column>
                <Form>
                  <Column>
                    <Form.Input
                      icon='envelope'
                      type='text'
                      placeholder='email'
                      label='Email'
                      onChange={event => this.setState({ email: event.target.value })}
                    />
                    <Form.Input
                      icon='lock'
                      placeholder='password'
                      label='Password'
                      type='password'
                      onChange={event => this.setState({ password: event.target.value })}
                    />
                    <Form.Input
                      icon='lock'
                      placeholder='Confirm password'
                      label='Confirm Password'
                      type='password'
                      onChange={event => this.setState({ password2: event.target.value })}
                    />
                    <Button onClick={this.handleSubmit}>Register</Button>
                    {this.state.errors ? <Message negative>{this.state.errorMessage}</Message> : null}
                    {this.state.password !== this.state.password2 &&
                    this.state.password.trim().length > 5 &&
                    this.state.password2.trim().length > 5 ? (
                      <Message>Password mismatch</Message>
                    ) : null}
                  </Column>
                </Form>
              </Column>
              {/* 
        // @ts-ignore */}
              <Column />
            </Row>
            <Row>
              <Column>
                <p style={{ marginTop: '7px', marginLeft: '4px' }}>
                  Already registered?
                  <span> </span>
                  <Link to='/login'>Login</Link>
                </p>
              </Column>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <h1>Who are you?</h1>
            </Row>
            <Row>
              <Column>
                <Message
                  style={{ cursor: 'pointer' }}
                  onClick={this.handleIsApplicant}
                  icon='child'
                  header='I am an applicant. I want to get a job.'
                />
              </Column>
              <Column>
                <Message
                  style={{ cursor: 'pointer' }}
                  onClick={this.handleIsEmployer}
                  icon='bullhorn'
                  header='I am an employer. I want to post a job.'
                />
              </Column>
            </Row>
          </>
        )}

        <Dimmer inverted active={this.state.loading}>
          <Loader content='Registering' />
        </Dimmer>
      </Container>
    );
  }
}

export default Register;
